import de.hsg.sumu.api.models.NewsResponse
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.json.*
import io.ktor.client.plugins.kotlinx.serializer.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import kotlinx.browser.localStorage
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.launch
import kotlinx.coroutines.suspendCancellableCoroutine
import kotlinx.serialization.Serializable
import org.w3c.files.File
import org.w3c.files.FileReader
import kotlin.coroutines.resumeWithException

val client = HttpClient {
    install(JsonPlugin) {
        serializer = KotlinxSerializer(kotlinx.serialization.json.Json {
            ignoreUnknownKeys = true
        })
    }
    install(Auth) {
        bearer {
            this.loadTokens {
                val accessToken: String? = localStorage.getItem("accessToken")
                val refreshTokens: String? = localStorage.getItem("refreshToken")
                println(accessToken)
                accessToken?.run { BearerTokens(accessToken, refreshTokens!!) }
            }
            refreshTokens {
                val refreshToken = oldTokens?.refreshToken
                if (refreshToken.isNullOrEmpty()) {
                    return@refreshTokens null
                }
                val response = client.submitForm(
                    url = "https://keycloak.hsgsumu.de/auth/realms/hsgsumu/protocol/openid-connect/token",
                    formParameters = Parameters.build {
                        append("grant_type", "refresh_token")
                        append("client_id", "webclient")
                        append("refresh_token", oldTokens?.refreshToken ?: "")
                    }

                ) {
                    markAsRefreshTokenRequest() }
                println(response.status.isSuccess())
                if (!response.status.isSuccess()) {
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("refreshToken")
                    null
                } else {
                    val refreshTokenInfo: TokenInfo = response.body()
                    localStorage.setItem("accessToken", refreshTokenInfo.access_token)
                    BearerTokens(refreshTokenInfo.access_token, oldTokens?.refreshToken!!)
                }
            }
            sendWithoutRequest { true }
        }
    }
    this.followRedirects = true
    this.expectSuccess = true
}

suspend fun getNews(): Page<NewsResponse> {
    return client.get("/api/news") {
    }.body()
}

suspend fun publishToArtikelstar(newsId: Int) {
    client.post("/api/news/$newsId/artikelstar").status
}

suspend fun publishPreviewToArtikelstar(state: ArtikelstarPreviewState) {
    client.post("/api/games/artikelstar/preview") {
        parameter("start", state.start)
        parameter("end", state.end)
    }.status
}


suspend fun uploadTest(file: File) {
    GlobalScope.launch {
        val res: String = file.getContent()
        client.submitFormWithBinaryData(formData = formData {
            append("test", res, headers = Headers.Empty)
        }) {
            onUpload { bytesSentTotal, contentLength ->
                val percentage = (bytesSentTotal * 100.0f) / contentLength
                console.log("Uploaded $bytesSentTotal of $contentLength: $percentage")
            }
        }
    }
}

suspend fun File.getContent(): String = suspendCancellableCoroutine { cont ->
    val reader = FileReader()
    reader.onload = {
        @Suppress("UnsafeCastFromDynamic")
        cont.resume(reader.result, null)
    }
    reader.onerror = { e ->
        cont.resumeWithException(Exception(e.type))
    }
    reader.readAsDataURL(this@getContent)
}

@Serializable
data class News(
    val id: Int,
    val title: String,
)

@Serializable
data class Page<T>(
    val content: List<T>,
    val currentPage: Int,
)
