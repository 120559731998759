package service

import TokenInfo
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import client
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.serialization.Serializable

object AuthService {

    var loggedIn by mutableStateOf(localStorage.getItem("accessToken") != null)
        private set

    private var accessToken by mutableStateOf(localStorage.getItem("accessToken"))

    var username by mutableStateOf(localStorage.getItem("username"))

    suspend fun performLogin(code: String) {
        val res = client.submitForm(
            url = "https://keycloak.hsgsumu.de/auth/realms/hsgsumu/protocol/openid-connect/token",
            formParameters = Parameters.build {
                append("grant_type", "authorization_code")
                append("code", code)
                append("client_id", "webclient")
                append("redirect_uri", "${window.location.protocol}//${window.location.host}/authorize")
            }
        )
        if (!res.status.isSuccess()) {
            // TODO: better error handling
            error("")
        } else {
            val body: TokenInfo = res.body()
            localStorage.setItem("accessToken", body.access_token)
            accessToken = body.access_token
            localStorage.setItem("refreshToken", body.refresh_token)
            loggedIn = true
            userinfo()
        }
    }

    fun logout() {
        loggedIn = false
        localStorage.clear()
        username = ""
    }

    val authUrl: String = "https://keycloak.hsgsumu.de/auth/realms/hsgsumu/protocol/openid-connect/auth?grant_type=code&response_type=code&client_id=webclient&redirect_uri=${window.location.protocol}//${window.location.host}/authorize"

    suspend fun userinfo() {
        val jwt = localStorage.getItem("accessToken")
        if (jwt != null && this.username == null) {
            val res = client.get("https://keycloak.hsgsumu.de/auth/realms/hsgsumu/protocol/openid-connect/userinfo") {
                bearerAuth(jwt)
            }
            val username = res.body<UserInfo>().name
            this.username = username
            localStorage.setItem("username", username)
        }
    }
}

@Serializable
data class UserInfo(
    val name: String,
)
