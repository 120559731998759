import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import de.hsg.sumu.api.models.GameResponse
import de.hsg.sumu.api.models.GameResult
import de.hsg.sumu.api.models.NewsResponse
import kotlinx.datetime.internal.JSJoda.DateTimeFormatter
import kotlinx.datetime.internal.JSJoda.Instant
import kotlinx.datetime.internal.JSJoda.LocalDate
import kotlinx.datetime.internal.JSJoda.ZoneId
import layout.Article
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.bottom
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.rgba
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Style
import org.jetbrains.compose.web.dom.Text

@Composable
fun newsTopOverview() {

    var newsList: List<NewsResponse> by remember { mutableStateOf(listOf()) }


    Style {
        className("card-header").style {
            backgroundColor(rgba(0, 0, 0, 0.8))
            color(Color.white)
            marginBottom(0.cssRem)
            padding(.5.cssRem)
        }
    }

    Div(attrs = {classes("d-flex", "flex-column")}) {
        Div {
            newsList.forEach { firstNews ->
                Article(
                    header = firstNews.title,
                    subHeaderLeft = firstNews.teams.map { it.ageclass }.toSet().joinToString(", "),
                    subHeaderRight = firstNews.authors.joinToString(", ") { "${it.firstname} ${it.lastname}" } + " " + DateTimeFormatter.ofPattern(
                        "dd.MM.yyyy"
                    ).format(
                        LocalDate.ofInstant(
                            Instant.ofEpochMilli(firstNews.date.toEpochMilliseconds()),
                            ZoneId.UTC
                        )
                    )
                ) {
                    Div(attrs = {
                        classes("ratio", "ratio-4x3", "d-flex", "align-content-end")
                    }) {
                        Img("https://hsg-sulzbach-murrhardt.de/${firstNews.picture}",
                            attrs = {
                                classes("object-fit-cover")
                                style {
                                    this.property("object-fit", "cover")
                                }
                            }
                        )
                        if (firstNews.games.isNotEmpty()) {
                            firstNews.games.forEach { game ->

                            Div(attrs = {
                                classes("w-100", "p-2")
                                style {
                                    color(Color.white)
                                    textAlign("center")
                                    height(auto)
                                    bottom(0.px)
                                    top(auto)
                                    when {
                                        game.result == null || game.result?.winner == GameResult.Winner.NONE -> backgroundColor(
                                            rgba(30, 30, 30, 0.75)
                                        )

                                        (game.home!!.isOwn && game.result?.winner == GameResult.Winner.HOME) || (game.away!!.isOwn && game.result?.winner == GameResult.Winner.AWAY) -> backgroundColor(
                                            rgba(30, 200, 30, 0.75)
                                        )

                                        else -> backgroundColor(rgba(255, 30, 30, 0.75))
                                    }
                                }
                            }) {
                                Text(game.toSubHeadline())
                            }
                        }
                        }
                    }

                    Div(attrs = {
                        classes("p-2", "rounded-bottom")
                        style {
                            backgroundColor(rgba(0, 0, 0, 0.8))
                            color(Color.white)
                        }
                    }) {
                        Text("Weiterlesen")
                    }
                }
            }
        }
    }

    LaunchedEffect("news") {
        newsList = getNews().content
    }
}

private fun GameResponse.toSubHeadline(): String {
    val drawingString = when {
        this.home!!.name.contains("HSG Sulz-Murr") || this.home!!.name.contains("HSG Sulzbach-Murrhardt") -> "${this.home!!.ageclass} - ${this.away!!.name}"
        else -> "${this.home!!.name} - ${this.away!!.ageclass}"
    }
    return when (this.result) {
        null -> this.gameTime.toString() + ": $drawingString"
        else -> "$drawingString ${this.result!!.homeScore}:${this.result!!.awayScore}"
    }
}


