import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import app.softwork.bootstrapcompose.Button
import app.softwork.bootstrapcompose.Color
import app.softwork.bootstrapcompose.Container
import app.softwork.bootstrapcompose.Input
import app.softwork.bootstrapcompose.Row
import app.softwork.bootstrapcompose.require
import app.softwork.routingcompose.BrowserRouter
import app.softwork.routingcompose.Router
import de.hsg.sumu.api.models.NewsResponse
import io.ktor.http.*
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.toLocalDateTime
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.renderComposable
import service.AuthService
import sidebar.games.sidebar
import views.header.header
import kotlin.time.Duration.Companion.days

object State1 {
    var count by mutableStateOf(0)
}



fun main() {
    require("./custom.scss")
    renderComposable(rootElementId = "root") {
        LaunchedEffect("load-username") {
            AuthService.userinfo()
        }
        var myText: String by remember { mutableStateOf("") }
        var selectedNews: NewsResponse? by remember { mutableStateOf(null) }
        BrowserRouter(initPath = "") {
            val router = Router.current
            route("/authorize") {
                val url = Url(window.location.href)
                val queryParams: Map<String, String> = url.encodedQuery.split("&")
                    .associate { it.substringBefore("=") to it.substringAfter("=") }
                val code = queryParams["code"] ?: error("Code not submitted.")

                LaunchedEffect("authorize") {
                    AuthService.performLogin(code)
                    router.navigate("/")
                }
            }

            Container(fluid = true) {
                header()
            }
            Container(fluid = false) {

                Row {

                    Div(attrs = {
                        this.classes("col-lg-8", "col-12")
                    }) {

                        route("/artikelstar") {
                            require(AuthService.username != null)
                            var state by remember { mutableStateOf(ArtikelstarPreviewState()) }
                            var news: List<NewsResponse> by remember { mutableStateOf(listOf()) }

                            news.forEach {
                                Div {
                                    var disabled by mutableStateOf(false)
                                    Text(it.title)
                                    val scope = rememberCoroutineScope()
                                    Button(title = "Publish", color = Color.Primary, attrs = {
                                        if (disabled) {
                                            disabled()
                                        }
                                    }) {
                                        disabled = true
                                        scope.launch {
                                            publishToArtikelstar(it.id)
                                            disabled = false
                                        }
                                    }
                                }
                            }

                            Div {
                                val scope = rememberCoroutineScope()
                                Input(label = "Start", type = InputType.Date, value = state.start.toLocalDateTime(TimeZone.UTC).date.toString()) {
                                    val date = LocalDate.parse(it.value).atStartOfDayIn(TimeZone.UTC)
                                    state = state.copy(start =date)
                                }
                                Input(label = "End", type = InputType.Date, value = state.end.toLocalDateTime(TimeZone.UTC).date.toString()) {
                                    val date = LocalDate.parse(it.value).atStartOfDayIn(TimeZone.UTC)
                                    state = state.copy(end = date)
                                }
                                Button(title = "Vorschau veröffentlichen") {
                                    scope.launch {
                                        publishPreviewToArtikelstar(state)
                                    }
                                }
                            }

                            LaunchedEffect("news") {
                                news = getNews().content
                            }
                        }
                        route("/teams") {
                            int { teamId -> MainScope().launch { myText = "$teamId" } }
                        }
                        route("/") {
                            Container(fluid = false) {
                                newsTopOverview()
                            }
                        }
                    }

                    Div(attrs = {
                        this.classes("col-lg-4", "d-none", "d-lg-block", "sidebar")
                    }) {
                        sidebar()
                    }
                }
                Text(myText)
            }
        }
    }
}

@Serializable
data class TokenInfo(
    val access_token: String,
    val refresh_token: String,
)

@Serializable
data class ArtikelstarPreviewState(
    val start: Instant = Clock.System.now(),
    val end: Instant = start.plus(7.days)
)

