object Routing {
    const val ROOT = "/"

    object Teams {
        private const val ROOT = "${Routing.ROOT}teams"
        val overview = ROOT
        fun withId(teamId: Int): Team = Team(teamId, ROOT)
    }

    class Team internal constructor(id: Int, base: String) {
        val overview = "$base/$id"
    }

    object Infos {
        val Hallenheft = "${ROOT}infos/hallenheft"
    }

    object Gallery {
        val overview = "${ROOT}gallery"
    }

    const val IMPRINT = "${ROOT}impressum"
    const val PRIVACY = "${ROOT}privacy"
}
