package layout

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.background
import org.jetbrains.compose.web.css.color
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.paddingBottom
import org.jetbrains.compose.web.css.paddingLeft
import org.jetbrains.compose.web.css.paddingRight
import org.jetbrains.compose.web.css.paddingTop
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.right
import org.jetbrains.compose.web.css.textAlign
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H5
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLDivElement

@Composable
fun Article(
    header: String,
    subHeaderLeft: String,
    subHeaderRight: String,
    content: ContentBuilder<HTMLDivElement>
) {
    Div(attrs = {
        classes("rounded", "border", "border-1", "border-black")
        style { marginBottom(1.cssRem) }
    }) {
        Div(attrs = {
            classes("d-flex", "card-header", "justify-content-around", "rounded-top")
            style {
                position(Position.Relative)
            }}) {
            H5(attrs = { classes("p-0", "m-0", "news-title") }) {
                Text(header)
            }
        }

        Div(attrs = {
            classes("d-flex", "justify-content-between")
            style {
                position(Position.Relative)
                color(Color.white)
                left(0.cssRem)
                    right(0.cssRem)
                    paddingTop(.25.cssRem)
                    paddingBottom(.25.cssRem)
                    paddingLeft(1.cssRem)
                    paddingRight(1.cssRem)
                    background("linear-gradient(0deg, rgba(70, 70, 70, 0.2) 0%, rgba(70, 70, 70, 0) 30%, rgba(0, 0, 0, 0) 70%, rgba(70, 70, 70, 0.2) 100%), linear-gradient(120deg, #d8424e 0%, #f5525e 50%, #4377b3 50%, #5387d3 100%")
                }
            }) {
                    Div {
                        Text(subHeaderLeft)
                    }
                    Div(attrs = { style {
                        textAlign("right")
                    }}) {
                        Text(subHeaderRight)
                    }
            }
        Div(attrs = {
            style {
                position(Position.Relative)
                marginTop(0.cssRem)
                padding(0.cssRem)
            }
        }, content = content)
    }
}
