package views.header

import Routing
import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import app.softwork.bootstrapcompose.Brand
import app.softwork.bootstrapcompose.Breakpoint
import app.softwork.bootstrapcompose.Color
import app.softwork.bootstrapcompose.Link
import app.softwork.bootstrapcompose.NavbarCollapseBehavior
import app.softwork.bootstrapcompose.NavbarDropDown
import app.softwork.bootstrapcompose.NavbarLink
import app.softwork.bootstrapcompose.Offcanvas
import app.softwork.bootstrapcompose.OffcanvasPlacement
import app.softwork.bootstrapcompose.OffcanvasState
import app.softwork.bootstrapcompose.TogglerPosition
import org.jetbrains.compose.web.css.maxHeight
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Nav
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLAnchorElement
import service.AuthService


@Composable
fun header() {
    Navbar(
        colorScheme = Color.Light,
        backgroundColor = Color.Light,
        fluid = false,
        collapseBehavior = NavbarCollapseBehavior.AtBreakpoint(Breakpoint.Large),
        brand = {
            Brand {
                Link(Routing.ROOT) {
                    Img(src = "https://hsg-sulzbach-murrhardt.de/css/images/logo_hsg_hover.png") {
                        style { maxHeight(80.px) }
                    }
                }
            }
        },
        navAttrs = {
            classes("me-auto")
        },
        toggler = true,
        togglerPosition = TogglerPosition.Left,
        additionalCollapsedContent = {
            val offCanvasState = remember { OffcanvasState() }

            P(attrs = {
                onClick {
                    offCanvasState.show()
                }
            }) {
                Text("Sidebar")
            }

            Offcanvas(OffcanvasPlacement.END, breakpoint = Breakpoint.Large, offcanvasState = offCanvasState, headerContent = { }, showHeaderCloseButton = true) {
                Text("Off-Canvas Sidebar")
            }
        },
        additionalNavContent = {
            if (AuthService.loggedIn) {
                Nav(attrs = { classes("m-0", "navbar-nav") }) {
                    NavbarLink(active = false) {
                        Text("Hallo ${AuthService.username}!")
                    }
                    val logoutAction: AttrBuilderContext<HTMLAnchorElement> = { onClick { AuthService.logout() } }
                    NavbarLink(active = false, attrs = logoutAction) {
                        Text("Logout")
                    }
                }
            } else {
                Nav(attrs = { classes("m-0", "navbar-nav") }) {
                    NavbarLink(
                        active = false,
                        link = AuthService.authUrl
                    ) {
                        Text("Anmelden")
                    }
                    NavbarLink(active = false) {
                        Text("Registrieren")
                    }
                }
            }
        }
    ) {
        NavbarDropDown("Mannschaften", href = Routing.Teams.overview) {
            Custom { A(href = Routing.Teams.withId(123).overview, attrs = {classes("dropdown-item") }) { Text("Männer") } }
        }
        NavbarDropDown("Informationen", href = "/infos") {
            Custom { A(href = Routing.Infos.Hallenheft, attrs = {classes("dropdown-item") }) { Text("Hallenheft") } }
        }
        NavbarLink(active = false, link = Routing.Gallery.overview) {
            Text("Galerie")
        }
        NavbarLink(active = false, link = Routing.IMPRINT) {
            Text("Impressum")
        }
        NavbarLink(active = false, link = Routing.PRIVACY) {
            Text("Datenschutz")
        }
    }
}
