package views.header

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import app.softwork.bootstrapcompose.Breakpoint
import app.softwork.bootstrapcompose.Color
import app.softwork.bootstrapcompose.NavbarCollapse
import app.softwork.bootstrapcompose.NavbarCollapseBehavior
import app.softwork.bootstrapcompose.NavbarNav
import app.softwork.bootstrapcompose.NavbarPlacement
import app.softwork.bootstrapcompose.Styling
import app.softwork.bootstrapcompose.Toggler
import app.softwork.bootstrapcompose.TogglerPosition
import kotlinx.uuid.UUID
import org.jetbrains.compose.web.dom.AttrBuilderContext
import org.jetbrains.compose.web.dom.ContentBuilder
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLButtonElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLElement

@Composable
public fun Navbar(
    collapseBehavior: NavbarCollapseBehavior = NavbarCollapseBehavior.Always,
    fluid: Boolean = false,
    placement: NavbarPlacement = NavbarPlacement.Default,
    containerBreakpoint: Breakpoint? = null,
    colorScheme: Color = Color.Light,
    backgroundColor: Color = Color.Primary,
    toggler: Boolean = true,
    togglerPosition: TogglerPosition = TogglerPosition.Right,
    togglerTargetId: String = remember { "toggler${UUID()}" },
    togglerAttrs: AttrBuilderContext<HTMLButtonElement>? = null,
    styling: (Styling.() -> Unit)? = null,
    attrs: AttrBuilderContext<HTMLElement>? = null,
    navAttrs: AttrBuilderContext<HTMLDivElement>? = null,
    additionalNavContent: ContentBuilder<HTMLDivElement>? = null,
    additionalCollapsedContent: ContentBuilder<HTMLDivElement>? = null,
    brand: ContentBuilder<HTMLDivElement>,
    navItems: ContentBuilder<HTMLDivElement>
) {
    app.softwork.bootstrapcompose.Navbar(
        collapseBehavior,
        fluid,
        placement,
        containerBreakpoint,
        colorScheme,
        backgroundColor,
        styling,
        attrs
    ) {
        if (togglerPosition == TogglerPosition.Right) {
            brand()
        }

        if (toggler) {
            Toggler(
                target = togglerTargetId,
                controls = togglerTargetId,
                styling = styling,
                attrs = togglerAttrs
            )
        }

        if (togglerPosition == TogglerPosition.Left) {
            brand()
        }

        if (toggler) {
            NavbarCollapse(togglerTargetId) {
                NavbarNav(attrs = { navAttrs?.invoke(this) }) {
                    navItems()
                }
                if (additionalNavContent != null) {
                    additionalNavContent()
                }
            }
            console.log(additionalCollapsedContent)
            console.log(collapseBehavior)
            if(additionalCollapsedContent != null && collapseBehavior is NavbarCollapseBehavior.AtBreakpoint) {
                val doNotShowUntilLarge = Styling {
                    Layout.display(app.softwork.bootstrapcompose.Layout.Display.None, breakpoint = collapseBehavior.breakpoint)
                }.generate()
                Div(attrs = {
                    classes(doNotShowUntilLarge)
                }) {
                    additionalCollapsedContent()
                }
            }
        } else {
            NavbarNav(attrs = { navAttrs?.invoke(this) }) {
                navItems()
            }
            if (additionalNavContent != null) {
                additionalNavContent()
            }
        }
    }
}
