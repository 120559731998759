package de.hsg.sumu.api.models

import kotlinx.datetime.Instant
import kotlinx.serialization.Serializable

@Serializable
data class NewsResponse(
    val id: Int,
    val title: String,
    val content: String,
    val date: Instant,
    val authors: List<NewsResponseAuthors>,
    val games: List<GameResponse>,
    // TODO: change to ageclass
    val teams: List<TeamResponse>,
    val picture: String
)


@Serializable
data class NewsResponseAuthors(
    val id: Int,
    val firstname: String,
    val lastname: String
)

@Serializable
data class GameResponse(
    val id: Int,
    val result: GameResult? = null,
    val home: TeamResponse? = null,
    val away: TeamResponse? = null,
    val gameTime: Instant? = null,
)

@Serializable
data class TeamResponse(
    val id: Int,
    val name: String,
    val ageclass: String,
    val picture: String? = null,
    val isOwn: Boolean,
)

@Serializable
data class GameResult(
    val homeScore: Int,
    val awayScore: Int,
    val winner: Winner
)
{
    /**
     *
     * Values: HOME,AWAY,NONE
     */
    enum class Winner {
        HOME,
        AWAY,
        NONE;
    }
}
